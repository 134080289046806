import { useRef, useEffect, useState } from "react";

export default function Preferences({map, route, tracker, routeList, updateSelectedRoute, selectedRoute, startTime, updateSelectedStartTime, setResetZoom, isRouteReversed, updateIsRouteReversed, showGradeColor, updateShowGradeColor}) {
    const position = 'bottom-right'
    const myPrefsButton = useRef(null)
    const buttonMapRef = useRef(null)
    const [lastCoords, setLastCoords] = useState([])
    const myRoute = useRef(null)


    useEffect(() => {
        const updateRouteFile = (routeFile) => {
            updateSelectedRoute(routeFile)
        }
        const updateStartTime = (startTime) => {
            updateSelectedStartTime(startTime)
        }
        var newCoords
        if (tracker) {
            newCoords = tracker.lastPoint.getCoords()
        }
        if (route && tracker && routeList && map && (!arraysAreEqual(newCoords,lastCoords) || route !== myRoute.current)) {
            setLastCoords(newCoords)
            myRoute.current = route
            if (!myPrefsButton.current) {
                myPrefsButton.current = new PrefsButton(map, route, tracker, routeList, selectedRoute, updateRouteFile, startTime, updateStartTime, setResetZoom, isRouteReversed, updateIsRouteReversed, showGradeColor, updateShowGradeColor)
                map.on('load', () => {
                    buttonMapRef.current = map.addControl(myPrefsButton.current, position)
                    myPrefsButton.current.update(map, route, tracker, routeList)
                })
            } else {
                // never called? What? How does it even work?
                myPrefsButton.current.update(map, route, tracker, routeList, selectedRoute)
            }
        }
    }, [map, route, tracker, routeList, selectedRoute, updateSelectedRoute, lastCoords, startTime, updateSelectedStartTime, setResetZoom, isRouteReversed, updateIsRouteReversed, showGradeColor, updateShowGradeColor])

    return null
}

// There may be floating point precision danger here
function arraysAreEqual(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
}

class PrefsButton {
    routeButton
    trackButton
    routeListButton
    prefsMenuDiv

    route
    tracker
    routeList
    selectedRoute
    updateRouteFile

    setResetZoom

    constructor(map, route, tracker, routeList, selectedRoute, updateRouteFile, startTime, updateStartTime, setResetZoom, isRouteReversed, updateIsRouteReversed, showGradeColor, updateShowGradeColor) {
        this.route = route
        this.tracker = tracker
        this.routeList = routeList
        this.updateRouteFile = updateRouteFile
        this.selectedRoute = selectedRoute
        this.setResetZoom = setResetZoom
        
        this.routeButton = document.createElement("button")
        this.routeButton.innerHTML = `<img src="/icons/route-svgrepo-com.svg" title="zoom to route" alt="zoom to route" width="18px" height="18px"/>`

        this.trackButton = document.createElement("button")
        this.trackButton.innerHTML = `<img src="/icons/location-arrow-svgrepo-com.svg" title="zoom to tracker" alt="zoom to tracker" width="18px" height="18px"/>`

        this.startTime = startTime
        this.updateStartTime = updateStartTime

        this.isRouteReversed = isRouteReversed
        this.updateIsRouteReversed = updateIsRouteReversed

        this.showGradeColor = showGradeColor
        this.updateShowGradeColor = updateShowGradeColor

        this.routeListButton = document.createElement("button")
        this.routeListButton.classList.add("prefs-menu-button")
        this.routeListButton.innerHTML = this.getPrefsButtonHTML()
    }

    update(map, route, tracker, routeList, selectedRoute, isRouteReversed) {
        if (map && this.tracker) {
            // Truncates to 10 decimals for floating point precision errors 
            // Considered putting this in the trackerClient but this is where the flyTo for the tracker already exists
            const center = map.getCenter()
            const shortCenter = [center.lng.toFixed(10),center.lat.toFixed(10)]
            const shortTracker = [this.tracker.lastPoint.lon.toFixed(10), this.tracker.lastPoint.lat.toFixed(10)]
            if (arraysAreEqual(shortCenter, shortTracker)) {
                this.flyToTracker(map, tracker)
            }
        }
        this.route = route
        this.tracker = tracker
        this.routeList = routeList
        this.selectedRoute = selectedRoute
        this.isRouteReversed = isRouteReversed
        this.updateListeners(map)
    }

    addListeners(map, routeButton, trackButton, routeListButton) {
        routeButton.addEventListener("click", () => {
            map.fitBounds(this.route.bounds, { padding: 40 })
            this.setResetZoom(true)
        })
        trackButton.addEventListener("click", () => this.flyToTracker(map, this.tracker))
        routeListButton.addEventListener("click", () => {this.toggleMinimized()})
        console.log("added prefs listeners")
    }

    updateListeners(map, setResetZoom) {
        var newRouteButton = this.routeButton.cloneNode(true)
        var newTrackButton = this.trackButton.cloneNode(true)
        var newRouteListButton = this.routeListButton.cloneNode(true)
        this.addListeners(map, newRouteButton, newTrackButton, newRouteListButton)
        if (this.routeButton.parentNode) {
            this.routeButton.parentNode.replaceChild(newRouteButton, this.routeButton)
            this.trackButton.parentNode.replaceChild(newTrackButton, this.trackButton)
            this.routeListButton.parentNode.replaceChild(newRouteListButton, this.routeListButton)
        }
        this.routeButton = newRouteButton
        this.trackButton = newTrackButton
        this.routeListButton = newRouteListButton
    }

    onAdd(map) {
        const div = document.createElement("div")

        const div1 = document.createElement("div")
        div1.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
        div1.appendChild(this.routeButton)
        div1.appendChild(this.trackButton)

        const div2 = document.createElement("div")
        div2.className = "mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-compact prefs-menu"
        div2.appendChild(this.getPrefsHTML())
        const routeListButtonHolder = document.createElement("div")
        routeListButtonHolder.className = "button-holder"
        routeListButtonHolder.appendChild(this.routeListButton)
        div2.appendChild(routeListButtonHolder)
        div.appendChild(div1)
        div.appendChild(div2)

        this.prefsMenuDiv = div2
        this.div = div
        return div
    }

    onRemove(map) {
        this.div.remove()
    }

    flyToTracker(map, tracker) {
        const zoomBearing = tracker.lastPoint.course ? tracker.lastPoint.course : 0 // garmin stopped setting course when not moving 20231206
        map.flyTo({center: tracker.lastPoint.getCoords(), bearing: zoomBearing, zoom: 13, pitch: 70})
    }

    toggleMinimized() {
        console.log("'click!'")
        if (this.prefsMenuDiv.classList.contains("mapboxgl-compact-show")) {
            this.prefsMenuDiv.classList.remove("mapboxgl-compact-show")
        } else {
            this.prefsMenuDiv.classList.add("mapboxgl-compact-show")
        }
    }

    getPrefsButtonHTML() {
        return '<img src="/icons/hamburger-menu-svgrepo-com.svg" title="settings" alt="settings" width="18px" height="18px"/>'
    }

    getPrefsHTML() {
        const prefsDiv = document.createElement("div")
        prefsDiv.className = "prefs-menu-inner"
        prefsDiv.appendChild(this.getTimePickerHTML())
        prefsDiv.appendChild(this.getRoutePickerHTML())
        prefsDiv.appendChild(this.getOptionsHTML())
        return prefsDiv
    }

    getTimePickerHTML() {
        // time picker. unordered list with lh header and li time picker
        const timeSelection = document.createElement("ul")

        const timeSelectionHeader = document.createElement("lh")
        timeSelectionHeader.innerText = "Start Time"
        timeSelection.appendChild(timeSelectionHeader)

        const timeSelectionItem = document.createElement('li')
        timeSelection.appendChild(timeSelectionItem)

        const timeInput = document.createElement("input")
        timeInput.type = "datetime-local"
        timeInput.id = "startTime"
        timeInput.value = this.startTime ? this.startTime : ""
        timeInput.addEventListener("change", (event) => this.updateStartTime(event.target.value))

        timeSelectionItem.appendChild(timeInput)
        return timeSelection
    }

    getRoutePickerHTML() {
        // route picker. unordered list with lh header and li routes
        const routeSelection = document.createElement("ul")

        const routeListHeader = document.createElement("lh")
        routeListHeader.innerText = "Select Route"
        routeSelection.appendChild(routeListHeader)

        for (const routeFile in this.routeList) {
            const routeListItem = document.createElement("li")
            routeListItem.innerHTML = `<input type="radio" id="${routeFile}" name="route-list"><label for="${routeFile}">${this.routeList[routeFile]}</label>`
            routeListItem.firstChild.checked = (routeFile === this.selectedRoute)

            // TODO: use change event on form instead?
            routeListItem.addEventListener("click", () => {this.updateRouteFile(routeFile)})
            routeSelection.appendChild(routeListItem)
        }
        return routeSelection
    }

    getOptionsHTML() {
        const options = document.createElement("ul")
        
        const optionsHeader = document.createElement("lh")
        optionsHeader.innerHTML = "Options"
        options.appendChild(optionsHeader)

        // Reverse route option
        const reverseRouteItem = document.createElement("li")

        const reverseRouteInput = document.createElement("input")
        reverseRouteInput.type = "checkbox"
        reverseRouteInput.id = "reverseRoute"
        reverseRouteInput.checked = this.isRouteReversed === true
        reverseRouteInput.addEventListener("change", (event) => this.updateIsRouteReversed(event.target.checked))
        reverseRouteItem.appendChild(reverseRouteInput)

        // TODO label is not full width of li so whitespace is not clickable
        const reverseRouteLabel = document.createElement("label")
        reverseRouteLabel.setAttribute("for", "reverseRoute")
        reverseRouteLabel.innerHTML = "Reverse Route"
        reverseRouteItem.appendChild(reverseRouteLabel)

        options.appendChild(reverseRouteItem)

        // Grade color option
        const gradeColorItem = document.createElement("li")

        const gradeColorInput = document.createElement("input")
        gradeColorInput.type = "checkbox"
        gradeColorInput.id = "gradeColor"
        gradeColorInput.checked = this.showGradeColor === true
        gradeColorInput.addEventListener("change", (event) => this.updateShowGradeColor(event.target.checked))
        gradeColorItem.appendChild(gradeColorInput)

        // TODO label is not full width of li so whitespace is not clickable
        const gradeColorLabel = document.createElement("label")
        gradeColorLabel.setAttribute("for", "gradeColor")
        gradeColorLabel.innerHTML = "Show Grade Colors"
        gradeColorItem.appendChild(gradeColorLabel)

        options.appendChild(gradeColorItem)


        return options
    }
}