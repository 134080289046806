import React, { useEffect, useRef } from 'react';

import {
  Chart as ChartJS,
  BubbleController,
  LineController,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Tooltip,
} from 'chart.js'

import { Line } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'

ChartJS.register(
  BubbleController,
  LineController,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Tooltip,
  zoomPlugin,
)

export default function ElevationChart({ units, route, trackerIndex, trackerName, className, resetZoom, zoomReset, showGradeColor}) {
  const chartRef = useRef()
  const data = useRef()
  const options = useRef()
  const myRoute = useRef()
  const myTrackerIndex = useRef()

  const lineColor = '#6cf' //'#6cf' // bda = green // def = 6cf4 but opaque
  const lineFillColor = `${lineColor}4`
  const trackerColor = '#00f'
  const trackerFillColor = `${trackerColor}6`

  // Update when route changes
  const myElevations = useRef([])
  const myWaypoints = useRef([])

  // Update when route || trackerIndex changes
  const myTracker = useRef([])

  // Options
  const myShowGradeColor = useRef()

  useEffect(() => {
    if (resetZoom === true) {
      zoomReset()
      const chart = chartRef.current
      chart.resetZoom()
    }
  }, [resetZoom, zoomReset])

  // TODO: use chart.zoomScale(scaleID, newRange, mode) to zoom chart to points visible in map
  const enableZoom = true
  const zoomOptions = {
    zoom: {
      mode: 'x',
      drag: {
        enabled: true,
        borderColor: lineColor,
        borderWidth: 1,
        //backgroundColor: lineFillColor,
      },
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      limits: {
        x: {min: 'original'}
      }
    }
  }

  const getChartOptions = (route, trackerIndex, trackerName) => {
    return {
      responsive: true,
      parsing: false,
      maintainAspectRatio: false,
      nearest: trackerIndex,
      interaction: { 
        mode: 'x',
        intersect: false,
      },
      plugins: {
        zoom: enableZoom ? zoomOptions : null,
        colors: {
          forceOverride: true
        },
        tooltip: {
          callbacks: {
            title: (items) => {
              return `Distance: ${items[0].raw.x.toFixed(2)}`
            },
            label: (context) => {
              if (context.dataset.label === 'tracker') {
                return trackerName
              } else if (context.dataset.label === 'waypoints') {
                return `${route.waypoints[context.dataIndex].name}`
              } else {
                let result = []
                for (let ds of context.chart.data.datasets) {
                  if (ds.data[context.dataIndex]) {
                    if (ds.label !== 'waypoints' && ds.label !== 'tracker') {
                      result.push(`${ds.label}: ${ds.data[context.dataIndex]?.y.toFixed(2)}`)
                    }
                  }
                }
                // It's slightly faster to generate these in the tooltip rather than when loading the route
                result.push(`Grade: ${route.getPoint(context.dataIndex).grade.toFixed(2)}`)
                const trackerDiff = route.getPoint(context.dataIndex).dist - route.getPoint(trackerIndex).dist
                result.push(`${trackerDiff > 0 ? "To go" : "Since"}: ${units.getDistance(Math.abs(trackerDiff)).toFixed(2)}`)
                return result
              }
            }
          }
        }
      },
      scales: {
        y: {
          beginAtZero: false,
          scaleLabel: {
            labelString: 'Feet'
          }
        },
        x: {
          // I shouldn't have to set the max x here but if I don't it goes to the nearest tick
          max: Math.ceil(units.getDistance(route.getPoint(route.length - 1).dist)),
          type: 'linear',
          display: true,
          scaleLabel: {
            labelString: 'Miles'
          }
        }
      },
      mouseLine: {
        color: '#6cf'
      }
    }
  }

  const plugins = [
    {
      id: 'mouseLine',
      afterDraw: chart => {
        if (chart.tooltip?._active?.length) {
          let x = chart.tooltip._active[0].element.x
          let yAxis = chart.scales.y
          let ctx = chart.ctx
          ctx.save()
          ctx.beginPath()
          ctx.moveTo(x, yAxis.top)
          ctx.lineTo(x, yAxis.bottom)
          ctx.lineWidth = 1
          ctx.strokeStyle = chart.options.mouseLine.color
          ctx.stroke()
          ctx.restore()
        }
      }
    }
  ]

  const getData = (tracker, elevations, waypoints, route, showGradeColor) => {
    let data = {
      datasets: [{
        order: 2,
        type: 'line',
        label: 'Elevation',
        data: elevations,
        borderColor: lineColor,
        backgroundColor: lineFillColor,
        fill: true,
        pointRadius: 0,
        pointHitRadius: (point) => {
          return point.index % 2 === 0 ? 2 : 0
        },
        segment: {
          backgroundColor: (segment) => {
            if (showGradeColor) {
              let grade = route.path.getPoint(segment.p0.$context.dataIndex).grade
              switch (true) {
                // wahoo colors
                case grade > 12: return "#f004"
                case grade > 8: return "#f804"
                case grade > 4: return "#ff04"
                case grade > 0: return "#0f04"
                default: return lineFillColor
              }
            } else {
              return lineFillColor
            }
          }
        }
      }]
    }
    if (waypoints.length > 0) {
      data.datasets.push({
        type: 'bubble',
        label: 'waypoints',
        data: waypoints,
        borderColor: lineColor,
        backgroundColor: '#fff',
        hoverRadius: 6,
        pointRadius: 5,
        hitRadius: 1,
      })
    }
    if (tracker.length > 0) {
      data.datasets.push({
        type: 'bubble',
        label: 'tracker',
        order: 1,
        data: tracker,
        borderColor: trackerColor,
        backgroundColor: trackerFillColor,
        hoverRadius: 6,
        pointRadius: 5,
        hitRadius: 1,
      })
    }
    return data
  }

  // "main"
  // TODO write a logging library and use it for log levels
  console.log("chart called")

  if (units && route && (route !== myRoute.current || trackerIndex !== myTrackerIndex.current)) {
    console.log("chart run")

    // Update when route changes
    let elevations = []
    let waypoints = []

    // Update when route || trackerIndex changes
    let tracker = []

    if (route !== myRoute.current) {
      for (let point of route.path.points) {
        const x = units.getDistance(point.dist)
        elevations.push({
          x: x,
          y: units.getElevation(point.ele),
        })
      }
      myElevations.current = elevations
      
      if (route && route.waypoints.length > 0) {
        for (let waypoint of route.waypoints) {
          const bubble = {
            x: units.getDistance(waypoint.dist),
            y: units.getElevation(waypoint.ele),
          }
          waypoints.push(bubble)
        }
      }
      myWaypoints.current = waypoints
    }

    if (myTracker.current !== tracker && trackerIndex && trackerIndex <= route.length) {
      tracker.push({
        x: units.getDistance(route.getPoint(trackerIndex).dist),
        y: units.getElevation(route.getPoint(trackerIndex).ele),
      })
      myTracker.current = tracker
    }

    myRoute.current = route
    myTrackerIndex.current = trackerIndex
    myShowGradeColor.current = showGradeColor

    data.current = getData(myTracker.current, myElevations.current, myWaypoints.current, myRoute.current, myShowGradeColor.current)
    myTrackerIndex.current = trackerIndex
    options.current = getChartOptions(route, trackerIndex, trackerName)
  }

  if (route && data.current && className && options.current) {
    return <Line ref={chartRef} options={options.current} plugins={plugins} data={data.current} className={className} />
  } else {
    return <></>
  }
}
